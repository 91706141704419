import React from 'react'
import { config, useSpring } from 'react-spring'
import Layout from '../components/layout'
import theme from '../../config/theme'
import { AnimatedBox } from '../elements'
import SEO from '../components/SEO'

const Contact = () => {
  const pageAnimation = useSpring({
    config: config.slow,
    from: { opacity: 0 },
    to: { opacity: 1 },
  })

  return (
    <Layout color={theme.colors.primary}>
      <SEO />
      <AnimatedBox style={pageAnimation} py={[6, 6, 6, 8]} px={[3, 3, 4, 3, 4, 6]}>
        <div>
          <h1 style={{ color: theme.colors.primary }}>Contact</h1>
          <p>
            I am currently taking both in-person/online Bharatnatyam classes in Redmond and Sammamish, WA. Bharatanatyam is one of the oldest classical dance traditions in India. It requires the dancer to be both flexible and fit, so my classes would incorporate basic stretches and strength building.
          </p>
          <p>
            If you are interested in learning Bharatanatyam in the Greater Seattle area, or want to get some basic stretches done to work towards your fitness, feel free to reach out to me at <span style={{ color: theme.colors.secondary }}>bhargavi@tandavalasya.com</span>.
          </p>
        </div>
      </AnimatedBox>
    </Layout>
  )
}

export default Contact
